import { store } from "../store/store";
import axios from "axios";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import moment from "moment";
import { isWorkingShift } from "../utils/helpers";

export default class ExcelManager {

    static getInstance() {
        if (!this.instance) {
            this.instance = new ExcelManager()
        }
        return this.instance;
    }

    /**
     * Function to generate and download report
     */
    async generateAndDownloadComptaReport() {
        const workbook = new ExcelJS.Workbook()
        workbook.creator = 'Groupe Vital Dev'
        workbook.created = new Date()

        let rowHeaderProps = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cccccc' }
        }

        let borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        }
        let planningSheet = workbook.addWorksheet('Planning')
        let row = planningSheet.addRow([
            "Nom société",
            "Matricule",
            "Salariés",
            "H100",
            "H125",
            "H200",
            "Majoration Heures Nuit 50",
            "Total (Heures)",
            "Rapport (%)",
            "Astreint (Jours)"
        ])
        row.eachCell(cell => {
            cell.border = borderStyle
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
            cell.fill = rowHeaderProps;
        })

        const state = store.getState()
        state.collabs.list.map(collab => {
            let collabHours = this.getCollabHours(collab.id)
            let overall = this.calculateTotalHoursForCollab(collabHours)
            let astreint = this.getTotalAstreintForCollab(collab.id)
            row = planningSheet.addRow([
                collab.company || "",
                collab.matricule || "",
                `${collab.prenom} ${collab.nom}`,
                collabHours.h100.toFixed(2),
                collabHours.h125.toFixed(2),
                collabHours.h200.toFixed(2),
                collabHours.hmn.toFixed(2),
                overall.total.toFixed(2),
                overall.ratio.toFixed(2),
                astreint.toFixed(2)
            ])
            row.eachCell(cell => {
                cell.border = borderStyle
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
            })
        })

        planningSheet = this.autoWidth(planningSheet)

        const buffer = await workbook.xlsx.writeBuffer()
        var blob = new Blob([buffer])
        let date = new moment(state.planning.selectedDate, "x")
        saveAs(blob, `Export_${date.format("MMMM_YYYY")}.xlsx`)
    }

    /**
     * Get collab planning hours
     * @param {*} collabId 
     * @returns 
     */
    getCollabHours(collabId) {
        const state = store.getState()
        let heureDebutNuit = 21, heureFinNuit = 7
        state.settings.settingsList.map(item => {
            if (item.label === "Heure début nuit") heureDebutNuit = item.settingValue
            if (item.label === "Heure fin nuit") heureFinNuit = item.settingValue
        })

        let hoursObj = {
            h100: 0,
            h125: 0,
            h200: 0,
            hmn: 0
        }

        state.planning.planningList
            .filter(s => (s.id_collab == collabId && isWorkingShift(s)))
            .map(shift => {
                let startDate = new Date(shift.from)
                let endDate = new Date(shift.to)
                let nightStart = new Date(startDate.getTime())
                nightStart.setHours(heureDebutNuit)
                nightStart.setMinutes(0)
                let nightEnd = new Date(startDate.getTime())
                nightEnd.setDate(nightEnd.getDate() + 1)
                nightEnd.setHours(heureFinNuit)
                nightEnd.setMinutes(0)
                let shiftDuration = ((shift.to - shift.from) / (60 * 60 * 1000))

                // Calculate extra & night hours
                let heuresNuit = 0, heuresSupp = 0, heuresNormal = 0
                if (endDate.getTime() > nightStart.getTime()) {
                    heuresNuit += ((endDate.getTime() - nightStart.getTime()) / (60 * 60 * 1000))
                    if (endDate.getTime() > nightEnd.getTime()) {
                        heuresNuit -= ((endDate.getTime() - nightEnd.getTime()) / (60 * 60 * 1000))
                    }
                }
                if (shiftDuration > 8) {
                    heuresNormal += 8
                    heuresSupp += shiftDuration - 8
                } else {
                    heuresNormal += shiftDuration
                }
                hoursObj.hmn += heuresNuit

                // Attribute normal hours to day of the week
                if (startDate.getDay() == 0) {
                    hoursObj.h200 += heuresNormal + heuresSupp
                } else if (startDate.getDay() == 6) {
                    hoursObj.h125 += heuresNormal + heuresSupp
                } else {
                    hoursObj.h100 += heuresNormal
                    hoursObj.h125 += heuresSupp
                }
            })
        return hoursObj
    }

    /**
     * Get collab planning hours
     * @param {*} collabId 
     * @returns 
     */
    getTotalAstreintForCollab(collabId) {
        const state = store.getState()
        let total = 0
        state.planning.planningList
            .filter(s => (s.id_collab == collabId && !s.absent && ['AST'].includes(s.type)))
            .map(shift => {
                let shiftDuration = ((shift.to - shift.from) / (60 * 60 * 1000))
                total += shiftDuration
            })
        console.log(total)
        return total / 24
    }

    /**
     * 
     * @param {*} hoursObj 
     * @returns 
     */
    calculateTotalHoursForCollab(hoursObj) {
        const state = store.getState()
        let monthHours = 151.67
        state.settings.settingsList.map(item => {
            if (item.label === "Base horaire mensuelle") monthHours = item.settingValue
        })
        let h100 = hoursObj.h100,
            h125 = hoursObj.h125,
            h200 = hoursObj.h200,
            hmn = hoursObj.hmn
        let total = h100, ratio = 0
        // Adjust the normal hours from extra hours
        while (total < monthHours && (h125 > 0 || h200 > 0 || hmn > 0)) {
            let missingNormalHours = monthHours - total
            if (hmn > 0) {
                let remainingRealHours = this.getRemainingRealHours(hmn / 2, missingNormalHours)
                total += (hmn / 2) - remainingRealHours
                hmn = remainingRealHours * 2
            } else if (h125 > 0) {
                let remainingRealHours = this.getRemainingRealHours(h125, missingNormalHours)
                total += h125 - remainingRealHours
                h125 = remainingRealHours
            } else if (h200 > 0) {
                let remainingRealHours = this.getRemainingRealHours(h200, missingNormalHours)
                total += h200 - remainingRealHours
                h200 = remainingRealHours
            }
        }
        //Now add the remaining extra hours
        if (hmn > 0) {
            total += hmn / 2
        }
        if (h125 > 0) {
            total += h125 * 1.25
        }
        if (h200 > 0) {
            total += h200 * 2
        }
        ratio = (total / monthHours) * 100
        return { total, ratio }
    }

    /**
     * 
     * @param {*} realHours 
     * @param {*} missingNormalHours 
     * @returns 
     */
    getRemainingRealHours(realHours, missingNormalHours) {
        if (realHours <= missingNormalHours) {
            return 0
        } else {
            return realHours - missingNormalHours
        }
    }

    /**
     * Function to generate and download data in excel format
     * @param {Array} data 
     * @param {String} name 
     */
    async generateAndDownloadExcelFromData(data, name) {
        const workbook = new ExcelJS.Workbook()
        workbook.creator = 'Groupe Vital Dev'
        workbook.created = new Date()

        let rowHeaderProps = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cccccc' }
        }

        let borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        }
        let sheet = workbook.addWorksheet('Export')
        if (data.length) {
            let row = sheet.addRow(Object.keys(data[0]))
            row.eachCell(cell => {
                cell.border = borderStyle
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                cell.fill = rowHeaderProps;
            })

            data.map(item => {
                row = sheet.addRow(Object.values(item))
                row.eachCell(cell => {
                    cell.border = borderStyle
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                })
            })

            sheet = this.autoWidth(sheet)
        }

        const buffer = await workbook.xlsx.writeBuffer()
        var blob = new Blob([buffer])
        saveAs(blob, `Export_${name}.xlsx`)
    }

    /**
     * Autofit columns by width
     * @param {*} worksheet 
     * @param {*} minimalWidth 
     * @returns 
     */
    autoWidth(worksheet, minimalWidth = 10) {
        worksheet.columns.forEach((column) => {
            let maxColumnLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                maxColumnLength = Math.max(
                    maxColumnLength,
                    minimalWidth,
                    cell.value ? cell.value.toString().length : 0
                );
            });
            column.width = maxColumnLength + 2;
        })
        return worksheet
    }


    async GenerateAndDownloadAnnualPrimeReport(jsonData, name) {
        const workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet('Evaluations');

        worksheet.columns = [
            { header: '', key: 'name', width: 40 },
            { header: '', key: 'matricule', width: 20 },
            { header: '', key: 'jan', width: 10 },
            { header: '', key: 'feb', width: 10 },
            { header: '', key: 'mar', width: 10 },
            { header: '', key: 'apr', width: 10 },
            { header: '', key: 'may', width: 10 },
            { header: '', key: 'jun', width: 10 },
            { header: '', key: 'jul', width: 10 },
            { header: '', key: 'aug', width: 10 },
            { header: '', key: 'sep', width: 10 },
            { header: '', key: 'oct', width: 10 },
            { header: '', key: 'nov', width: 10 },
            { header: '', key: 'dec', width: 10 },
            { header: 'Date début', key: 'start_date', width: 20 },
            { header: 'Date fin', key: 'end_date', width: 20 }
        ];
        let monthKeys = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
        
        jsonData.forEach(collab => {
            let row = worksheet.addRow({});
            worksheet.mergeCells(`C${row.number}:F${row.number}`);
            worksheet.getCell(`C${row.number}`).value = 'Q1';
            worksheet.getCell(`C${row.number}`).alignment = { horizontal: 'center' };
            worksheet.mergeCells(`G${row.number}:J${row.number}`);
            worksheet.getCell(`G${row.number}`).value = 'Q2';
            worksheet.getCell(`G${row.number}`).alignment = { horizontal: 'center' };
            worksheet.mergeCells(`K${row.number}:N${row.number}`);
            worksheet.getCell(`K${row.number}`).value = 'Q3';
            worksheet.getCell(`K${row.number}`).alignment = { horizontal: 'center' };
            let startDate = collab.start_date ? moment(collab.start_date).format('DD-MM-YYYY') : '';
            let endDate = collab.end_date ? moment(collab.end_date).format('DD-MM-YYYY') : '';

            row = worksheet.addRow({
                name: `${collab.prenom} ${collab.nom}`, matricule: collab.matricule,
                jan: "Jan", feb: "Fév", mar: "Mar", apr: "Avr", may: "Mai", jun: "Juin", jul: "Juil", aug: "Aoû",
                sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", start_date: startDate, end_date: endDate
            });
            worksheet.getCell(`A${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`C${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`D${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`E${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`F${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`G${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`H${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`I${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`J${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`K${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`L${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`M${row.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`N${row.number}`).alignment = { horizontal: 'center' };

            const fieldsTitles = ["Respect du contrat de prestation", "Assiduité et respect du planning", "Qualité de travail", "Comportement Général"];

            fieldsTitles.forEach(field => {
                let valuesRow = worksheet.addRow({ name: field, matricule: "Valeur", type: 'Value' });
                let commentsRow = worksheet.addRow({ matricule: "Commentaire", type: 'Comment' });
                collab.evaluationData.forEach((monthData, index) => {
                    monthData.evaluations.forEach(ev => {
                        if (ev.title === field) {
                            let monthIndex = monthKeys.indexOf(monthData.month);
                            if (monthIndex !== -1) {
                                let valueText = ''
                                if(ev.value == 'yes'){
                                    valueText = 'Oui'
                                }else{
                                    valueText = 'Non'
                                }
                                valuesRow.getCell(3 + monthIndex).value = valueText;
                                commentsRow.getCell(3 + monthIndex).value = ev.comments || '';
                                valuesRow.getCell(3 + monthIndex).alignment = { horizontal: 'center' };
                                commentsRow.getCell(3 + monthIndex).alignment = { horizontal: 'center' };
                            }
                        }
                    });
                });
                worksheet.mergeCells(`A${valuesRow.number}:A${commentsRow.number}`);
                worksheet.getCell(`A${valuesRow.number}`).alignment = { horizontal: 'center', vertical: 'middle' };
            });
            let ajRow = worksheet.addRow({
                name: 'Absence justifiée',
                jan: collab.leaves.Q1,
                may: collab.leaves.Q2,
                sep: collab.leaves.Q3
            });
            worksheet.mergeCells(`C${ajRow.number}:F${ajRow.number}`);
            worksheet.mergeCells(`G${ajRow.number}:J${ajRow.number}`);
            worksheet.mergeCells(`K${ajRow.number}:N${ajRow.number}`);
            worksheet.mergeCells(`A${ajRow.number}:B${ajRow.number}`);
            worksheet.getCell(`C${ajRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`G${ajRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`K${ajRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`A${ajRow.number}`).alignment = { horizontal: 'center' };


            let aiRow = worksheet.addRow({
                name: 'Absence injustifiée',
                jan: collab.absences.Q1,
                may: collab.absences.Q2,
                sep: collab.absences.Q3
            });
            worksheet.mergeCells(`C${aiRow.number}:F${aiRow.number}`);
            worksheet.mergeCells(`G${aiRow.number}:J${aiRow.number}`);
            worksheet.mergeCells(`K${aiRow.number}:N${aiRow.number}`);
            worksheet.mergeCells(`A${aiRow.number}:B${aiRow.number}`);
            worksheet.getCell(`C${aiRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`G${aiRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`K${aiRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`A${aiRow.number}`).alignment = { horizontal: 'center' };


            let pRow = worksheet.addRow({
                name: 'Prime',
                jan: collab.prime.Q1,
                may: collab.prime.Q2,
                sep: collab.prime.Q3
            });
            worksheet.mergeCells(`C${pRow.number}:F${pRow.number}`);
            worksheet.mergeCells(`G${pRow.number}:J${pRow.number}`);
            worksheet.mergeCells(`K${pRow.number}:N${pRow.number}`);
            worksheet.mergeCells(`A${pRow.number}:B${pRow.number}`);
            worksheet.getCell(`C${pRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`G${pRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`K${pRow.number}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`A${pRow.number}`).alignment = { horizontal: 'center' };
            worksheet.addRow()
        });
        const thickBorderStyle = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };

        function applyBorderToRange(worksheet, startRow, endRow, startCol, endCol, borderStyle) {
            for (let row = startRow; row <= endRow; row++) {
                for (let col = startCol; col <= endCol; col++) {
                    const cell = worksheet.getCell(row, col);
                    cell.border = borderStyle;
                }
            }
        }

        worksheet.eachRow((row, rowIndex) => {
            if (rowIndex >= 2) { 
                applyBorderToRange(worksheet, rowIndex, rowIndex, 3, 5, thickBorderStyle); 
                applyBorderToRange(worksheet, rowIndex, rowIndex, 6, 8, thickBorderStyle); 
                applyBorderToRange(worksheet, rowIndex, rowIndex, 9, 11, thickBorderStyle); 
                applyBorderToRange(worksheet, rowIndex, rowIndex, 10, 14, thickBorderStyle); 

            }
        });

        //worksheet = this.autoWidth(worksheet)
        // Write the workbook to a buffer and save
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        saveAs(blob, 'FormattedEvaluations.xlsx');
    };
}