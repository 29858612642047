import React, { useState, useEffect, useRef } from 'react';
import { addNewlyEvaluated } from "../../../components/collabs/collabsSlice";
import { useDispatch, useSelector } from "react-redux";
import Langs from '../../../app/lang/langs';
import { blockageDaytoFutureMonth, checkPermission, isEmpty } from '../../../app/utils/helpers';
import { Dialog } from 'primereact/dialog';
import { config } from '../../../app/utils/config';
import styles from '../EvaluationsView.module.css'
import { DynamicButton } from '../../../components/buttons/DynamicButton';
import { Col, Row } from 'react-bootstrap';
import {
    getEvaluationFields,
    setEvaluationAddModal,
    setOnEvaluateTeamId,
    setOnEvaluateCollab,
    setOnEvaluateDate,
    getMonthlyEvaluations,
    setMonthlyEvaluations,
    editEvaluation,
    setUpdateBody,
    setSelectedType,
    getEvaluationsTypes,
    setAllowedToEdit
} from '../../../components/evaluations/evaluationsSlice';
import { TransparentDatePicker } from '../../../components/buttons/TransparentDatePicker';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';


export default function AddEvaluationModal(props) {

    const dispatch = useDispatch()

    const visible = useSelector((state) => state.evaluations.evaluationAddModal)
    const collabs = useSelector((state) => state.collabs.list)
    const fields = useSelector((state) => state.evaluations.fields)
    const onEvaluateCollab = useSelector((state) => state.evaluations.onEvaluateCollab)
    const onEvaluateTeamId = useSelector((state) => state.evaluations.onEvaluateTeamId)
    const onEvaluateDate = useSelector((state) => state.evaluations.onEvaluateDate)
    const monthlyEvaluations = useSelector((state) => state.evaluations.monthlyEvaluations)
    const types = useSelector((state) => state.evaluations.types)
    const selectedType = useSelector((state) => state.evaluations.selectedType)
    const selectedTypeGeneric = useSelector((state) => state.evaluations.selectedTypeGeneric)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
    const allowedToEdit = useSelector((state) => state.evaluations.allowedToEdit)
    const isMobile = useSelector((state) => state.settings.isMobile)

    const mounted = useRef(null)

    const [currentFields, setCurrentFields] = useState([])
    const [evaluationDate, setEvaluationDate] = useState()
    const [selectedCollab, setSelectedCollab] = useState(null)
    const [errorFieldIds, setErrorFieldIds] = useState({})
    const [isEdit, setIsEdit] = useState(false)


    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        }
    }, [])

    useEffect(() => {
        if (onEvaluateDate) {
            setEvaluationDate(onEvaluateDate)
        } else {
            setEvaluationDate(null)
        }
    }, [onEvaluateDate])

    useEffect(() => {
        const collab = onEvaluateCollab
        if (collab) {
            setSelectedCollab(collab)
            reloadData(onEvaluateTeamId)
        } else {
            setSelectedCollab(null)
        }
    }, [collabs, onEvaluateCollab, onEvaluateTeamId, evaluationDate, selectedType])

    useEffect(() => {
        if (fields) {
            let oldMonthlyEvaluationsMap = monthlyEvaluations.reduce((obj, evaluation) => {
                obj[evaluation.id_field] = evaluation
                return obj
            }, {})

            setCurrentFields(fields.map(field => {
                let fieldData = {
                    id: field.id,
                    rank: field.rank,
                    title: field.title,
                    comments: ''
                }
                if (oldMonthlyEvaluationsMap && Object.keys(oldMonthlyEvaluationsMap).length) {
                    if (oldMonthlyEvaluationsMap[field.id]) {
                        fieldData = {
                            ...fieldData,
                            value: oldMonthlyEvaluationsMap[field.id].value,
                            comments: oldMonthlyEvaluationsMap[field.id].comments || '',
                            id_evaluation: oldMonthlyEvaluationsMap[field.id].id
                        }
                    }
                }
                return fieldData
            }))
        }
    }, [fields, monthlyEvaluations])

    useEffect(() => {
        checkFields()
    }, [currentFields])

    const handleInputChange = (event) => {
        let newFieldsArray = []

        currentFields.map(field => {
            let newField = { ...field }
            if (field.id == event.target.id) {
                if (event.target.name == "value") {
                    newField[event.target.name] = parseInt(event.target.value)
                } else {
                    newField[event.target.name] = event.target.value
                }
            }
            newFieldsArray.push(newField)
        })
        setCurrentFields(newFieldsArray)
    }

    const handleDateChange = (event) => {
        setEvaluationDate(event)
    }

    function getYear(date = evaluationDate) {
        return moment(date).year()
    }

    function getMonth(date = evaluationDate) {
        return moment(date).month() + 1
    }

    const handleClose = () => {
        dispatch(setEvaluationAddModal(false))
        dispatch(setAllowedToEdit(false))
        dispatch(setOnEvaluateCollab(null))
        dispatch(setOnEvaluateTeamId(null))
        dispatch(setOnEvaluateDate(null))
        setCurrentFields([])
        setSelectedCollab(null)
        dispatch(setMonthlyEvaluations([]))
        setIsEdit(false)
    }

    function checkFields() {
        let erroredIds = {}
        let isEdit = false
        currentFields.map(field => {
            if (field.value == 0 && (!field.comments || !field.comments.length)) {
                if (!erroredIds) erroredIds = {}
                erroredIds[field.id] = true
            }
            if (!isEmpty(field.id_evaluation)) {
                isEdit = true
            }
        })
        setErrorFieldIds(erroredIds)
        setIsEdit(isEdit)
        return Object.keys(erroredIds).length == 0
    }

    const handleUpdate = () => {
        if (checkFields()) {
            let editBody = []
            let newFields = currentFields.map((field) => {
                // default value = 1
                let updateValue = 1
                // if set -> replace
                if (typeof field.value != 'undefined') {
                    updateValue = field.value
                }
                let singleBody = {
                    value: updateValue,
                    comments: field.comments,
                    year: getYear(),
                    month: getMonth(),
                    id_collab: onEvaluateCollab?.id,
                    id_field: field.id
                }
                if (typeof field.id_evaluation != 'undefined')
                    singleBody.id = field.id_evaluation
                editBody.push(singleBody)

                field.value = updateValue
                return field
            })

            dispatch(addNewlyEvaluated([onEvaluateCollab?.id, editBody.filter(f => !f.id).length]))

            dispatch(editEvaluation(editBody))

            if (getYear() == getYear(props.selectedDate) &&
                (!selectedTypeGeneric || selectedType.id == selectedTypeGeneric.id)) {
                // update evaluations view only when same year
                dispatch(setUpdateBody({
                    month: getMonth(),
                    id_collab: onEvaluateCollab?.id,
                    id_team: selectedTeamsIds,
                    matricule: selectedCollab.matricule,
                    values: editBody.map(f => f.value)
                }))
            }
            handleClose()
        }
    }

    function reloadData(idTeam) {
        if (!idTeam && selectedCollab)
            idTeam = selectedCollab.id_team
        if (idTeam) {
            dispatch(getEvaluationsTypes({ idTeam: idTeam })).then(() => {
                let type = selectedType ? selectedType.id : null
                dispatch(getEvaluationFields({ idType: type, idTeam: idTeam }))
                if (evaluationDate) {
                    dispatch(getMonthlyEvaluations({
                        id_collab: onEvaluateCollab?.id,
                        month: getMonth(),
                        year: getYear(),
                        idType: type
                    }))
                }
            })
        }
    }

    const handleTypeChange = (e) => {
        dispatch(setSelectedType(e.value))
    }

    function formatIsoDateString(isoDateString) {
        const date = moment(isoDateString)
        let formattedTime = date.format('HH:mm')
        let formattedDate = date.format('DD/MM/YYYY')
        const now = moment()
        if (now.format('DD/MM/YYYY') == formattedDate) {
            return <span><Langs str="today at" /><b> {formattedTime}</b></span>
        }
        return <span><Langs str="the" /><b> {formattedDate} </b><Langs str="at" /><b> {formattedTime}</b></span>
    }

    function renderCreatedByAndAt() {
        let data = monthlyEvaluations && monthlyEvaluations[0]
        if (data && data.creator && data.created_at) {
            return (
                <p><Langs str="CREATED_BY" />:<b> {data.creator} </b>
                    {formatIsoDateString(data.created_at)}
                </p>
            )
        }
    }
    function renderUpdatedByandAt() {
        let data = monthlyEvaluations && monthlyEvaluations[0]
        if (data && data.updater && data.updated_at) {
            return (
                <p><Langs str="LAST_EDITED" />:<b> {data.updater} </b>
                    {formatIsoDateString(data.updated_at)}
                </p>
            )
        }
    }

    function renderDatePicker() {
        return (
            <TransparentDatePicker
                viewType="MONTHLY"
                onChange={handleDateChange}
                value={evaluationDate}
                disabled
            />
        )
    }

    const renderBody = () => {
        if (selectedCollab) {
            let selectedTeamId = selectedCollab.id_team
            let isEditMode = blockageDaytoFutureMonth(evaluationDate) && checkPermission(config.scopes.evaluations, config.permissionTypes.edit, selectedTeamId) && allowedToEdit
            if (isMobile == 1) {
                return (
                    <div className="mx-4 pb-4">
                        <Row className="bg-light py-2 mb-2">
                            <Col lg={5}></Col>
                            <Col lg={1} className='text-center'><Langs str="YES" /></Col>
                            <Col lg={1} className='text-center'><Langs str="NO" /></Col>
                            <Col lg={5} className='text-center'><Langs str="DESCRIPTION" /></Col>
                        </Row>
                        {currentFields.map((field) => (
                            <Row key={field.id} className="pb-2">
                                <Col lg={5} className='my-auto' style={{ color: errorFieldIds[field.id] ? "red" : "" }}>{<Langs str={field.title} />}{errorFieldIds[field.id] ? '*' : ''}</Col>
                                <Col lg={1} className="text-center my-auto">
                                    <input
                                        className='mx-auto py-auto'
                                        type='checkbox'
                                        id={field.id}
                                        name={"value"}
                                        checked={field.value == 1}
                                        value={1}
                                        onChange={handleInputChange}
                                        disabled={!isEditMode}
                                    />
                                </Col>
                                <Col lg={1} className="text-center my-auto">
                                    <input
                                        className='mx-auto py-auto'
                                        type='checkbox'
                                        id={field.id}
                                        name={"value"}
                                        checked={field.value == 0}
                                        value={0}
                                        onChange={handleInputChange}
                                        disabled={!isEditMode}
                                    />
                                </Col>
                                <Col lg={5} className='d-flex justify-content-inline'>
                                    <textarea
                                        name="comments"
                                        style={{ width: '100%' }} // Set styles normally here without !important
                                        className={errorFieldIds[field.id] ? "textarea-error" : ""}
                                        rows="2"
                                        id={field.id}
                                        value={field.comments}
                                        onChange={handleInputChange}
                                        disabled={!isEditMode}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </div>
                )
            }
            if (isMobile == 2 || isMobile == 3) {
                return (
                    <div className="mx-4 pb-4 mt-2">
                        {currentFields.map((field) => (
                            <Row key={field.id} className="pb-2 border-bottom">
                                <Col xs={12} className='mt-1 mb-3' style={{ color: errorFieldIds[field.id] ? "red" : "" }}>{<Langs str={field.title} />}:{errorFieldIds[field.id] ? '*' : ''}</Col>
                                <Col xs={4} className="mb-2 d-flex justify-content-around">
                                    <span className='my-auto'><Langs str='YES' /></span>
                                    <input
                                        className='mx-auto py-auto'
                                        type='checkbox'
                                        id={field.id}
                                        name={"value"}
                                        checked={field.value == 1}
                                        value={1}
                                        onChange={handleInputChange}
                                        disabled={!isEditMode}
                                    />
                                </Col>
                                <Col xs={4} className="mb-2 d-flex justify-content-around">
                                    <span className='my-auto'><Langs str='NO' /></span>
                                    <input
                                        className='my-auto'
                                        type='checkbox'
                                        id={field.id}
                                        name={"value"}
                                        checked={field.value == 0}
                                        value={0}
                                        onChange={handleInputChange}
                                        disabled={!isEditMode}
                                    />
                                </Col>
                                <Col xs={12} className='d-flex justify-content-inline mb-2'>
                                    <textarea
                                        name="comments"
                                        style={{ width: '100%' }} // Set styles normally here without !important
                                        className={errorFieldIds[field.id] ? "textarea-error" : ""}
                                        rows="2"
                                        id={field.id}
                                        value={field.comments}
                                        onChange={handleInputChange}
                                        disabled={!isEditMode}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </div>
                )
            }
        }
    }

    const headerElement = (
        <div className="">
            <Row className='d-flex justify-content-start align-items-center'>
                <Col lg={6} xs={12} className={`container ${isMobile == 3 ? 'd-flex align-items-center' : 'ps-4'}`}>
                    <div className="ps-4">
                        <Row>
                            <Col lg={12} className={isMobile == 3 ? 'me-2' : 'ps-4'}>
                                {isEdit ? <Langs str="EDIT_EVALUATION" /> : <Langs str="ADD_EVALUATION" />}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className={isMobile == 3 ? 'me-2' : 'ps-4'}>
                                {selectedCollab ? `${selectedCollab.nom} ${selectedCollab.prenom}` : ""}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg={2} xs={6} className='my-auto'>
                    {renderDatePicker()}
                </Col>
                <Col lg={4} xs={6} className='py-auto my-auto'>
                    <Dropdown
                        value={selectedType}
                        onChange={handleTypeChange}
                        options={types}
                        optionLabel="title"
                        placeholder="Type"
                        className="w-full border-0 fw-semibold text-black"
                        style={{ maxWidth: "100%" }}
                    />
                </Col>
            </Row>

        </div>
    )

    const actionButton = () => {
        if (selectedCollab) {
            let selectedTeamId = selectedCollab.id_team
            if (blockageDaytoFutureMonth(evaluationDate) &&
                checkPermission(config.scopes.evaluations, config.permissionTypes.edit, selectedTeamId) && allowedToEdit &&
                fields.length > 0) {
                if (isEdit) {
                    return (
                        <DynamicButton
                            onClick={handleUpdate}
                            className=''
                            title={<Langs str="edit" />}
                            active={true}
                            color="blue"
                        />
                    )
                } else if (selectedCollab) {
                    return (
                        <DynamicButton
                            onClick={handleUpdate}
                            className=''
                            title={<Langs str="ADD_EVALUATION" />}
                            active={true}
                            color="green"
                        />
                    )
                }
            } else {
                return (
                    <p style={{ textAlign: "center" }}>Le collaborateur est dans une autre équipe ce mois-ci</p>
                )
            }
        }
    }

    const footerContent = (
        <Row className='d-flex justify-content-start pb-2 mx-4 ms-2'>
            <Col lg={9} className='row'>
                <Col lg={12} className='text-start'>
                    {renderCreatedByAndAt()}
                </Col>
                <Col lg={12} className='text-start'>
                    {renderUpdatedByandAt()}
                </Col>
            </Col>
            <Col lg={3} className='my-auto'>
                {actionButton()}
            </Col>
        </Row>
    );

    return (
        <div>
            <Dialog
                visible={visible}
                draggable={false}
                contentClassName='pb-0'
                className={styles.dialogCustom}
                position='center'
                modal
                resizable={false}
                header={headerElement}
                footer={footerContent}
                onHide={handleClose}
            >
                {renderBody()}
            </Dialog>
        </div>
    )
}