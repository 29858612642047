import { React, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import GridLayout from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import {
  isAdmin,
  formatDate,
  getFormattedDate,
  showErrorToast,
  generateUniqueId,
  isWorkingShift,
  isOwner,
  checkPermission,
} from "../../app/utils/helpers";
import addUser from "../../assets/images/add-user-grey.png";
import addUserFocused from "../../assets/images/add-user.png";
import {
  updateShiftProperties,
  setShowFormPopup,
  fillPlanningList,
} from "../../containers/planning/planningSlice";
import ImageButton from "../buttons/ImageButton";
import { DateCell } from "./cells/DateCell.js";
import { ResourceRow } from "./rows/resourceRow.js";
import styles from "./Scheduler.module.css";
import { addShifts, updateShifts } from "./shifts/shiftsSlice";
import { getCalendarDaysDifference } from "../../app/utils/helpers";
import moment from "moment";
import { ConfirmModal } from "../popups/ConfirmModal";
import { config } from "../../app/utils/config.js";

export function Scheduler(props) {

  const dispatch = useDispatch()

  const WEEKLY_VIEW_DAYS = useSelector((state) => state.planning.WEEKLY_VIEW_DAYS)
  const settings = useSelector((state) => state.settings)
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const selectedGroupe = useSelector((state) => state.planning.selectedGroupe)
  const selectedDate = useSelector((state) => state.planning.selectedDate)
  const draggingItem = useSelector((state) => state.planning.draggingItem)
  const groupesList = useSelector((state) => state.groupes.list)
  const selectedView = useSelector((state) => state.planning.selectedView)
  const collabs = useSelector((state) => state.collabs.list)
  const resources = useSelector((state) => state.collabs.collaboratorsList)
  const userRoleId = useSelector((state) => state.login.userRoleId)
  const planningList = useSelector((state) => state.planning.planningList)
  const publications = useSelector((state) => state.planning.publications)
  const teams = useSelector((state) => state.teams)
  const sampleLeaves = useSelector((state) => state.sampleLeaves.list)
  const dynamicLeaves = useSelector((state) => state.sampleLeaves.dynamicLeaves)

  const [showConfirmAddShiftPopup, setShowConfirmAddShiftPopup] = useState(false)
  const [showConfirmReplaceShiftPopup, setShowConfirmReplaceShiftPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedShift, setSelectedShift] = useState(null)
  const [newShifts, setNewShifts] = useState([])
  const [shiftToReplace, setShiftToReplace] = useState(null)
  const [resourcesLayout, setResourcesLayout] = useState(null)
  const [amShifts, setAmShifts] = useState({})
  const [pmShifts, setPmShifts] = useState({})
  const [nightShifts, setNightShifts] = useState({})
  const [datesLayout, setDatesLayout] = useState([])
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()
  const [dailyHours, setDailyHours] = useState(8)
  const [uniqueCollabsIds, setUniqueCollabsIds] = useState([])

  let newShiftsCounter = {
    amShifts: {},
    pmShifts: {},
    nightShifts: {}
  }

  useEffect(() => {
    settings.settingsList.map(item => {
      if (item.label === "Base horaire quotidienne")
        setDailyHours(Number(item.settingValue))
    })
  }, [
    settings
  ])

  useEffect(() => {

    switch (selectedView) {
      case "WEEKLY":
        let weeklyLayout = constructWeeklyLayout()
        setDatesLayout(weeklyLayout)
        setWidth(weeklyLayout.length * 200)
        setHeight(34)
        break
      default:
        let monthlyLayout = constructMonthlyLayout()
        setDatesLayout(monthlyLayout)
        setWidth(monthlyLayout.length * 120)
        setHeight(34)
        break
    }
  }, [
    selectedView,
    selectedDate
  ])

  useEffect(() => {
    console.log("rendering")
    // reset resources counter
    newShiftsCounter = {
      amShifts: {},
      pmShifts: {},
      nightShifts: {}
    }
    setResourcesLayout(generateResourcesLayout())
  }, [
    planningList,
    collabs,
    groupesList,
    selectedDate,
    selectedView,
    resources,
    selectedTeamsIds,
    selectedGroupe,
    draggingItem
  ])

  function constructMonthlyLayout() {
    let layout = []
    let date = new Date(selectedDate)
    date.setDate(1)
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let i = 0
    while (date.getMonth() < month && date.getFullYear() <= year) {
      layout.push({
        i: "date_" + i,
        x: i,
        y: 0,
        w: 1,
        h: 2,
        static: true,
        data: {
          dateTime: date.getTime(),
          text: formatDate(date, "ddd D"),
          weekDay: date.getDay(),
        },
      })
      date.setDate(i + 2)
      i++
    }
    return layout
  }

  function constructWeeklyLayout() {
    let layout = []
    let date = new Date(selectedDate)

    for (let i = 0; i < WEEKLY_VIEW_DAYS; i++) {
      layout.push({
        i: "date_" + i,
        x: i,
        y: 0,
        w: 1,
        h: 2,
        static: true,
        data: {
          dateTime: date.getTime(),
          text: formatDate(date, "dddd D"),
          weekDay: date.getDay(),
        },
      })
      date.setHours(date.getHours() + 24)
    }
    return layout
  }

  /**
   * Function responsible of adding a shift item in planning through drag & drop
   * @param {*} resource 
   * @param {*} index 
   * @param {*} originalTeamId 
   */
  const handleShiftDrop = (resource, index, originalTeamId) => {

    if (!draggingItem) return
    let timeFrom = draggingItem["timeFrom"]
    let timeTo = draggingItem["timeTo"]
    let fromDate = new Date(selectedDate)
    fromDate.setDate(fromDate.getDate() + index)
    fromDate.setHours(parseInt(timeFrom / 60))
    fromDate.setMinutes(parseInt(timeFrom % 60))
    let toDate = new Date(fromDate.getTime())

    if (timeTo < timeFrom) {
      toDate.setHours(parseInt(timeTo / 60) + 24)
    } else {
      toDate.setHours(parseInt(timeTo / 60))
    }
    toDate.setMinutes(parseInt(timeTo % 60))

    let addedShift = {
      id: generateUniqueId(),
      id_collab: resource.value,
      type: draggingItem["type"],
      id_team: resource.id_team,
      from: fromDate.getTime(),
      to: toDate.getTime(),
      status: "CREATED",
      updatedAt: new Date().getTime()
    }

    if (originalTeamId != resource.id_team) {
      addedShift.id_team_delegate = originalTeamId
    }
    if (selectedGroupe) {
      addedShift.id_groupe = selectedGroupe
    }

    if (draggingItem["type"] == 'R') {
      let id_sample_leave = sampleLeaves.filter(s => s.type == draggingItem["type"])[0].id
      addedShift["id_sample_leave"] = id_sample_leave
    } else {
      if (draggingItem["task"]) {
        addedShift["task"] = draggingItem["task"];
        addedShift["id_sample_shift"] = draggingItem["id"]
      } else {
        addedShift["id_sample_leave"] = draggingItem["id"]
      }
    }

    const validationResult = validateShift(addedShift, resource);
    if (!validationResult.isValid) {
      setShowConfirmAddShiftPopup(true)
      setNewShifts([addedShift])
      const errorMessage = validationResult.errorMessage
      setErrorMessage(errorMessage)
    } else {
      handleAddShift([addedShift])
    }
  }

  function handleAddShift(addedShifts) {
    setShowConfirmAddShiftPopup(false)
    if (!addedShifts && newShifts.length) {
      addedShifts = newShifts
    }

    if (addedShifts && addedShifts.length) {
      dispatch(addShifts({ items: addedShifts })).then((response) => {
        if (response["payload"]["responseError"]) {
          showErrorToast()
          if (selectedShift) {
            dispatch(updateShiftProperties(selectedShift))
            setSelectedShift(null)
          }
        } else {
          dispatch(fillPlanningList(addedShifts))
          setNewShifts([])
        }
      })
    }
  }

  const handleCancelAddShift = () => {
    if (selectedShift) {
      dispatch(updateShiftProperties(selectedShift))
      setSelectedShift(null)
    }
    setShowConfirmAddShiftPopup(false)
    setNewShifts([])
  }

  const handleShiftsChange = (shiftsLayout, resource) => {
  }

  /**
   * Function responsible of dragging a shift item in planning
   * @param {*} shiftsLayout 
   * @param {*} item 
   * @param {*} resource 
   */
  const handleDragItem = (shiftsLayout, item, resource) => {
    let resourceShifts = planningList.filter((shift) => shift.id == item["i"])

    if (resourceShifts && resourceShifts[0]) {
      let shift = resourceShifts[0]
      let layouts = shiftsLayout.filter((layout) => layout["i"] == item["i"])

      if (layouts && layouts[0]) {
        let shiftLayout = layouts[0]
        let fromDate = new Date(shift["from"])
        let toDate = new Date(shift["to"])
        let daysDiff = getCalendarDaysDifference(fromDate, toDate)
        let date = new Date(datesLayout[0].data.dateTime)
        date.setDate(date.getDate() + shiftLayout.x)
        let day = date.getDate()
        let month = date.getMonth()

        fromDate.setMonth(month)
        toDate.setMonth(month)
        fromDate.setDate(day)
        toDate.setDate(day + daysDiff)

        let data = Object.assign({}, shift, {
          from: fromDate.getTime(),
          to: toDate.getTime(),
        })

        if (data.from !== shift.from || data.to !== shift.to) {
          const validationResult = validateShift(data, resource)
          if (!validationResult.isValid) {
            setErrorMessage(validationResult.errorMessage)
            setShiftToReplace({ data, shift, resource })
            setShowConfirmReplaceShiftPopup(true)
          } else {
            handleUpdateShift(data, shift, resource)
          }
        }
      }
    }
  }

  /**
   * 
   * @param {*} data 
   * @param {*} shift 
   * @param {*} resource 
   * @returns 
   */
  function handleUpdateShift(data = shiftToReplace.data, shift = shiftToReplace.shift, resource = shiftToReplace.resource) {
    if (!data || !shift || !resource) return

    data.status = "UPDATED"
    data.updatedAt = new Date().getTime()
    dispatch(updateShifts({ items: [data] })).then((response) => {
      if (response.payload.responseError) {
        showErrorToast()
        dispatch(updateShiftProperties(shift))
      } else {
        dispatch(updateShiftProperties(data))
      }
    })

    setShiftToReplace(null)
    setShowConfirmReplaceShiftPopup(false)
  }

  /**
   * Handle cancel update shift by drag
   */
  const handleCancelUpdateShift = () => {

    if (shiftToReplace && shiftToReplace.shift) {
      dispatch(updateShiftProperties(shiftToReplace.shift))
    }
    setShiftToReplace(null)
    setShowConfirmReplaceShiftPopup(false)
  }


  /**
   * Function to handle shift item resize ==> add new shifts on resize
   * @param {*} shiftsLayout 
   * @param {*} item 
   * @param {*} resource 
   */
  const handleResizeItem = (shiftsLayout, item, resource) => {

    let resourceShifts = planningList.filter((shift) => shift.id == item["i"])
    if (resourceShifts && resourceShifts[0]) {
      let shift = resourceShifts[0]
      setSelectedShift(shift)

      let layouts = shiftsLayout.filter((layout) => layout["i"] === item["i"])
      if (layouts && layouts[0]) {
        let shiftLayout = layouts[0]
        let fromDate = new Date(shift["from"])
        let toDate = new Date(shift["to"])

        if ((dynamicLeaves[shift.type] || shift.type == 'AST') && shiftLayout.w >= 1) {

          toDate.setFullYear(fromDate.getFullYear())
          toDate.setMonth(fromDate.getMonth())
          toDate.setDate(fromDate.getDate() + shiftLayout.w - 1)

          let data = {
            id: shift.id,
            from: fromDate.getTime(),
            to: toDate.getTime(),
            updatedAt: new Date().getTime()
          }
          dispatch(updateShifts({ items: [data] })).then((response) => {
            if (response["payload"]["responseError"]) {
              showErrorToast()
              dispatch(updateShiftProperties(shift))
            } else {
              dispatch(updateShiftProperties(data))
            }
          })
        } else if (shiftLayout.w > 1) {

          let addedShifts = []
          let days = shiftLayout.w

          let isLegalGapRespected = true
          let errorMessage = "";
          while (days > 1) {

            fromDate.setDate(fromDate.getDate() + 1)
            toDate.setDate(toDate.getDate() + 1)

            let addedShift = {
              id: generateUniqueId(),
              id_collab: resource.value,
              type: shift["type"],
              absent: shift["absent"],
              id_team: shift["id_team"],
              from: fromDate.getTime(),
              to: toDate.getTime(),
              status: "CREATED",
              updatedAt: new Date().getTime()
            }
            if (shift.id_team_delegate)
              addedShift["id_team_delegate"] = shift.id_team_delegate;
            if (shift.id_groupe)
              addedShift["id_groupe"] = shift.id_groupe;
            if (shift["task"])
              addedShift["task"] = shift["task"];
            if (shift["id_sample_shift"] != null)
              addedShift["id_sample_shift"] = shift["id_sample_shift"]
            else
              addedShift["id_sample_leave"] = shift["id_sample_leave"]

            let dateData = datesLayout.filter(dateLayout => {
              return formatDate(new Date(dateLayout.data.dateTime), "DD-MM-YYYY") == formatDate(fromDate, "DD-MM-YYYY")
            })

            if (dateData[0]) {
              addedShifts.push(addedShift)
            }

            const validationResult = validateShift(addedShift, resource);
            isLegalGapRespected = isLegalGapRespected && validationResult.isValid;

            if (!isLegalGapRespected) {
              errorMessage = validationResult.errorMessage; // Sauvegarde le premier message d'erreur rencontré
              break; // Sort de la boucle si une validation échoue
            }

            days--
          }

          if (addedShifts.length) {
            let itemsToAdd = addedShifts
            let normalDaysShifts = addedShifts.filter(s => !checkDayOff(s))
            let daysOffShifts = addedShifts.filter(s => checkDayOff(s))
            // if shifts to add includes days off, add only normal days
            if (daysOffShifts.length && normalDaysShifts.length) {
              itemsToAdd = normalDaysShifts
            }

            if (isLegalGapRespected) {
              handleAddShift(itemsToAdd)
            } else {
              setErrorMessage(errorMessage);
              setNewShifts(itemsToAdd)
              setShowConfirmAddShiftPopup(true)
            }
          }
        }
      }
    }
  }

  /**
   * Get the amout of extra work time per day per collab
   * @param {*} shifts 
   * @returns 
   */
  function getExtraTime(shifts, teamId) {
    let teamData = getTeamFromId(teamId)

    if (teamData) {
      let ranges = [], shiftHours = 0
      let teamLocalization = teamData.localization
      let teamLunchBreakDuration = teamData.lunchBreakDuration
      if (!["BEYROUTH"].includes(teamLocalization)) {
        let sortedShifts = [...shifts]
        // sort shifts from longer to shorter
        sortedShifts.sort((a, b) => {
          return (b.to - b.from) - (a.to - a.from)
        })
        // loop shifts and check for shared time
        sortedShifts.map((shift) => {
          if (isWorkingShift(shift) && !checkDayOff(shift)) {
            let from = parseInt(shift.from)
            let to = parseInt(shift.to)
            ranges.map(range => {
              if (from >= range.from && from <= range.to &&
                to >= range.from && to <= range.to) {
                // if shift in between older shifts skip
                from = 0
                to = 0
              } else {
                // adjust from, to in order to remove shared shift time
                if (from >= range.from && from <= range.to)
                  from = range.to
                if (to >= range.from && to <= range.to)
                  to = range.from
              }
            })
            shiftHours += (to - from) / (60 * 60 * 1000)
            ranges.push({ from: shift.from, to: shift.to })
          }
        })
      }
      if (shiftHours) {
        let extraTime = shiftHours - dailyHours
        return Math.round(extraTime * 2) / 2
      }
    }
    return 0
  }

  /**
   * Another way to calculate the extra time based on simpler logic
   * @param {*} shifts 
   * @param {*} teamData
   * @returns 
   */
  function getExtraTimeSimple(shifts, teamData) {
    let finalShift
    if (teamData) {
      let teamLocalization = teamData.localization
      let teamLunchBreakDuration = teamData.lunchBreakDuration
      let teamDailyhours = dailyHours
      teamData.settings.map(item => {
        if (item.label === "Base horaire quotidienne") teamDailyhours = Number(item.value)
      })
      if (!["BEYROUTH"].includes(teamLocalization)) {
        let sortedShifts = [...shifts]
        // sort shifts from longer to shorter
        sortedShifts.sort((a, b) => {
          return (b.to - b.from) - (a.to - a.from)
        })
        // loop shifts and check for shared time
        sortedShifts.map((shift) => {
          if (isWorkingShift(shift) && !checkDayOff(shift)) {
            let from = parseInt(shift.from)
            let to = parseInt(shift.to)
            if (finalShift) {
              if (finalShift.from > from) {
                finalShift.from = from
              }
              if (finalShift.to < to) {
                finalShift.to = to
              }

            } else {
              finalShift = {
                from: from,
                to: to
              }
            }
          }
        })
      }
      if (finalShift) {
        let shiftHours = (finalShift.to - finalShift.from) / (60 * 60 * 1000)
        if (shiftHours) {
          let extraTime = shiftHours - teamDailyhours
          return Math.round(extraTime * 2) / 2
        }
      }
    }
    return 0
  }

  /**
   * 
   * @param {*} resource 
   * @param {*} index 
   * @param {*} teamId 
   * @param {*} isAll 
   * @param {*} linkedGroupes 
   * @returns 
   */
  function constructLayoutForResource(resource, index, teamId, isAll, linkedGroupes) {

    let layout = []
    let totalExtraTime = 0

    for (let i = 0; i < datesLayout.length; i++) {
      let date = datesLayout[i].data.dateTime
      let shifts = getResourceShifts(date, resource, teamId)
      if (shifts.length) {
        for (let j = 0; j < shifts.length; j++) {
          let shift = shifts[j]
          let fromDate = new Date(shift.from)
          let toDate = new Date(shift.to)
          let viewFromDate = new Date(selectedDate)
          let viewToDate = new Date(selectedDate)
          viewToDate.setMonth(viewFromDate.getMonth() + 1)
          if (selectedView === "WEEKLY") {
            viewToDate = new Date(selectedDate)
            viewToDate.setDate(viewFromDate.getDate() + WEEKLY_VIEW_DAYS)
          }
          let diffDays = 0
          do {
            if (fromDate > viewFromDate && fromDate < viewToDate) {
              diffDays += 1
            }
            fromDate.setDate(fromDate.getDate() + 1)
          } while (toDate >= fromDate)

          let w = diffDays || 1
          let extraTime = 0
          let teamData = getTeamFromId(teamId)
          // Calculate extra time for the first row only or second if first is repos or astreint
          if ((j == 0 || (j >= 1 && !isWorkingShift(shifts[j - 1]))) && isWorkingShift(shift)) {
            //extraTime = getExtraTime(shifts, teamId)
            extraTime = getExtraTimeSimple(shifts, teamData)
          }

          totalExtraTime += extraTime
          // filter shifts by groupe if not "all" groupe selected
          if (resource.groupeIds && resource.groupeIds.length && !isAll) {
            if (!shift.id_sample_leave && shift.id_groupe != selectedGroupe && (!linkedGroupes || !linkedGroupes.includes(shift.id_groupe))) {
              continue
            }
          }

          // update the shifts counter ( 0 | 0 | 0)
          newShiftsCounter = updateShiftsCounter(shift, i, teamData, newShiftsCounter)

          layout.push({
            i: "" + shift["id"],
            x: i,
            y: j,
            w: w,
            h: ['AST'].includes(shift.type) ? 0.5 : 1,
            extraTime: extraTime,
            minH: ['AST'].includes(shift.type) ? 0.5 : 1,
            maxH: ['AST'].includes(shift.type) ? 0.5 : 1,
            data: shift,
            static: (!canEditPlanning(shift.id_team) || (shift['id_sample_leave'] && !dynamicLeaves[shift.type]))
          })
        }
      } else {
        // layout.push({
        //     i: nanoid(),
        //     x: i,
        //     y: 0,
        //     w: 1,
        //     h: 1,
        //     static: true,
        //     data: {
        //         placeholder: true
        //     }
        // })
      }
    }

    setAmShifts(newShiftsCounter.amShifts)
    setPmShifts(newShiftsCounter.pmShifts)
    setNightShifts(newShiftsCounter.nightShifts)

    return (
      <ResourceRow
        isAll={selectedGroupe === 0}
        key={"resource_" + index}
        groupe={groupesList.filter((groupe) =>
          resource.groupeIds.includes(groupe.id)
        )}
        isDroppable={canEditPlanning(teamId)}
        layout={layout}
        datesLayout={datesLayout}
        nbCols={datesLayout.length}
        resource={resource}
        color={index % 2 ? "grey" : "lightGrey"}
        onDropShift={handleShiftDrop}
        onLayoutChange={handleShiftsChange}
        onDragItem={handleDragItem}
        onResizeItem={handleResizeItem}
        originalTeamId={teamId}
      />
    )
  }

  /**
   * 
   * @param {*} shift 
   * @param {*} x 
   * @param {*} team 
   * @param {*} counter 
   */
  function updateShiftsCounter(shift, x, team, counter) {
    if (!counter.amShifts[x]) counter.amShifts[x] = []
    if (!counter.pmShifts[x]) counter.pmShifts[x] = []
    if (!counter.nightShifts[x]) counter.nightShifts[x] = []
    let from = new Date(shift.from)
    let to = new Date(shift.to)

    if (team && team.settings && team.settings.length && from && to) {

      let heureDebut = 9, heureFin = 17, heureDebutNuit = 22, heureFinNuit = 7, heureMidi = 14

      team.settings.map(item => {
        if (item.label === "Heure début de journée") heureDebut = Number(item.value)
        if (item.label === "Heure mi-journée") heureMidi = Number(item.value)
        if (item.label === "Heure fin de journée") heureFin = Number(item.value)
        if (item.label === "Heure début nuit") heureDebutNuit = Number(item.value)
        if (item.label === "Heure fin nuit") heureFinNuit = Number(item.value)
      })

      if (from.getHours() <= heureDebut && to.getHours() >= heureMidi) {
        if (!counter.amShifts[x].includes(shift.id)) counter.amShifts[x].push(shift.id)
      }
      if (from.getHours() <= heureMidi && to.getHours() >= heureDebutNuit) {
        if (!counter.pmShifts[x].includes(shift.id)) counter.pmShifts[x].push(shift.id)
      }
      if (from.getHours() >= heureFin && from.getHours() <= heureDebutNuit && to.getHours() >= heureFinNuit) {
        if (!counter.nightShifts[x].includes(shift.id)) counter.nightShifts[x].push(shift.id)
      }
    }
    return counter
  }

  /**
   * 
   * @param {*} dateTime 
   * @param {*} resource 
   * @param {*} originalTeamId 
   * @returns 
   */
  function getResourceShifts(dateTime, resource, originalTeamId) {
    let date = new Date(dateTime)
    let viewFromDate = new Date(selectedDate)
    let planningShifts = planningList.filter((shift) => {
      let fromDate = new Date(shift.from)
      let toDate = new Date(shift.to)
      // show all shifts from all teams only for admins
      let onlyTeamShifts = isOwner(userRoleId) || ((shift.id_team == originalTeamId && shift.id_team_delegate !== originalTeamId) || shift.id_team_delegate == originalTeamId)
      return (
        resource.value === shift.id_collab &&
        onlyTeamShifts &&
        (getFormattedDate(fromDate) === getFormattedDate(date) || (getFormattedDate(viewFromDate) === getFormattedDate(date) &&
          fromDate.getTime() <= viewFromDate.getTime() &&
          toDate.getTime() >= viewFromDate.getTime())
        )
      )
    })
    return planningShifts.sort((a, b) => parseInt(a.updatedAt) - parseInt(b.updatedAt))
  }

  /**
   * check if there's a legal gap betweenthe new shift and old shifts
   * @param {*} shift 
   * @returns 
   */
  function checkLegalGapBetweenShift(shift) {
    if (isWorkingShift(shift)) {
      let legalGap = 11 * 60 * 60 * 1000;
      let from = shift.from - legalGap;
      let to = shift.to + legalGap;
      let planningShifts = planningList.filter((s) => {
        return (isWorkingShift(s) &&
          s.id_collab === shift.id_collab &&
          (s.id_team === shift.id_team || s.id_team_delegate === shift.id_team_delegate) &&
          ((s.to > from && s.to < to) ||
            (s.from < to && s.from > from)))
      });
      if (planningShifts.length) {
        return { valid: false };
      }
    }
    return { valid: true };
  }

  /**
   * 
   * @param {*} shift 
   * @param {*} resource 
   * @returns 
   */
  function checkShiftWithinCollabDates(shift, resource) {
    console.log(resource)
    if (!isWorkingShift(shift)) {
      return { valid: true }
    } else if (resource.ancienneteeDate && (resource.exitDate === null || resource.exitDate)) {
      let entryDateMillis = new Date(resource.ancienneteeDate).getTime();
      let exitDateMillis = resource.exitDate ? new Date(resource.exitDate).getTime() : Infinity
      if (shift.from >= entryDateMillis && (resource.exitDate === null || shift.to <= exitDateMillis)) {
        return { valid: true }
      }
    }
    return { valid: false }
  }

  /**
   * 
   * @param {*} shift 
   * @param {*} resource 
   * @returns 
   */
  function validateShift(shift, resource) {
    const legalGapCheck = checkLegalGapBetweenShift(shift)
    const withinCollabDatesCheck = checkShiftWithinCollabDates(shift, resource)
    let components = []

    if (!legalGapCheck.valid) {
      components.push(<Langs key="legalGapWarn" str='SHIFT_LEGAL_GAP_WARN_MSG' />)
    }
    if (!withinCollabDatesCheck.valid) {
      // Ajoute un élément <br /> s'il y a déjà un message ajouté avant
      if (components.length > 0) components.push(<br key="separator" />)
      components.push(<Langs key="collabDateWarn" str='SHIFT_COLLAB_DATE_WARN_MSG' />)
    }

    // Construction du message d'erreur final
    let errorMessage = null;
    if (components.length > 0) {
      errorMessage = <div>{components}</div>
    }

    return {
      isValid: legalGapCheck.valid && withinCollabDatesCheck.valid,
      errorMessage: errorMessage
    }
  }

  function handleAddUser() {
    dispatch(setShowFormPopup({ type: "ADD_USER" }))
  }

  // const handleItemClick = ({ event, props }) => console.log(event, props);

  /**
   * Function to check wether the day in question is a working day or not
   * @param {*} data 
   * @returns {Boolean}
   */
  function checkDayOff(data) {
    let date = moment()
    if (data.dateTime) date = moment(data.dateTime, "x")
    else if (data.from) date = moment(data.from, "x")
    let weekDay = date.day()
    if (weekDay == 0 || weekDay == 6) return true
    let formattedDate = date.format("DD-MM-YYYY")
    if (settings.holidays[date.year()])
      for (let i = 0; i < settings.holidays[date.year()].length; i++) {
        const holiday = settings.holidays[date.year()][i]
        if (formattedDate === holiday.date)
          return true
      }
    return false
  }

  /**
   * 
   * @param {*} teamId 
   * @returns 
   */
  function getTeamFromId(teamId) {
    let teamsFiltered = teams.list && teams.list.filter(t => t.id == teamId)
    if (teamsFiltered && teamsFiltered[0]) {
      return teamsFiltered[0]
    }
    return null
  }

  /**
   * 
   * @param {*} teamId 
   * @returns 
   */
  function getTeamLabelFromId(teamId) {
    let label = ""
    let teamsFiltered = teams.list && teams.list.filter(t => t.id == teamId)
    if (teamsFiltered && teamsFiltered[0]) {
      label = teamsFiltered[0].label
    }
    if (publications[teamId]) {
      let pubDate = new Date(publications[teamId].created_at)
      label = <Langs str={"LABEL_WITH_LATEST_PUBLISH"} params={{ LABEL: label, DATE: formatDate(pubDate, 'DD-MM-YYYY HH:mm') }} />
    }
    return label
  }

  function generateResourcesLayout() {

    let viewFromDate = new Date(selectedDate)
    let viewToDate = new Date(selectedDate)
    viewToDate.setMonth(viewFromDate.getMonth() + 1)
    if (selectedView === "WEEKLY") {
      viewToDate = new Date(selectedDate)
      viewToDate.setDate(viewFromDate.getDate() + WEEKLY_VIEW_DAYS)
    }

    let uniqueCollabsIds = []
    if (selectedGroupe === 0) {
      let resourcesLayout = selectedTeamsIds.map((teamId) => (
        <div
          key={`LAYOUT_${teamId}`}
          className={styles.categoryView}
          style={{ width: width + 100, maxWidth: width + 100 }}
        >
          <Col xs={12} className={styles.teamName}>
            <label className={styles.groupeLabel}>
              {getTeamLabelFromId(teamId)}
            </label>
          </Col>
          {collabs
            .filter((collab) => {
              var entryDate = collab.ancienneteeDate ? new Date(collab.ancienneteeDate) : null
              var exitDate = collab.exitDate ? new Date(collab.exitDate) : null
              // check if collab didnt enter yet or did exit
              // let hasShifts = planningList.find(s=>s.id_collab==collab.id)
              if ((entryDate && entryDate.getTime() >= viewToDate.getTime()) ||
                (exitDate && exitDate.getTime() <= viewFromDate.getTime())) {
                return false
              }
              if ((collab.id_team == teamId && !collab.id_team_delegate) ||
                (collab.id_team_delegate && collab.id_team_delegate != collab.id_team &&
                  collab.id_team_delegate == teamId)) {
                if (!uniqueCollabsIds.includes(collab.id))
                  uniqueCollabsIds.push(collab.id)
                return true
              }
              return false
            })
            .map((collab, index) => {
              return constructLayoutForResource(collab, index, teamId, true)
            })}
        </div>
      ))
      setUniqueCollabsIds(uniqueCollabsIds)
      return resourcesLayout
    } else {
      let groupe = groupesList.filter(g => g.id == selectedGroupe)
      if (groupe[0]) {
        let filteredResourcesLayout = resources
          .filter((resource) => {
            let entryDate = resource.ancienneteeDate ? new Date(resource.ancienneteeDate) : null
            let exitDate = resource.exitDate ? new Date(resource.exitDate) : null
            // check if collab didnt enter yet or did exit
            if (
              (entryDate && entryDate.getTime() >= viewToDate.getTime()) ||
              (exitDate && exitDate.getTime() <= viewFromDate.getTime())) {
              return false
            }
            if (resource.groupeIds.includes(selectedGroupe)) {
              if (!uniqueCollabsIds.includes(resource.id))
                uniqueCollabsIds.push(resource.id)
              return true
            }
            return false
          })
          .map((resource, index) => constructLayoutForResource(resource, index, groupe[0].id_team, false, groupe[0].linked_groups))

        setUniqueCollabsIds(uniqueCollabsIds)
        return <div
          className={styles.categoryView}
          style={{ width: width + 100, maxWidth: width + 100 }}
        >
          <Col xs={12} className={styles.teamName}>
            <label className={styles.groupeLabel}>
              {getTeamLabelFromId(groupe[0].id_team)}
            </label>
          </Col>
          {filteredResourcesLayout}
        </div>
      }
    }
  }

  function isManager(teamId, scope) {
    if (!scope)
      scope = config.scopes.shifts
    return checkPermission(scope, config.permissionTypes.edit, teamId)
    return isAdmin(userRoleId) && (!teams.myTeam || teams.myTeam.userRoleId == 2 || teamId != teams.myTeam.id)
  }

  function canEditPlanning(teamId) {
    if (isOwner(userRoleId)) {
      return true
    }
    if (!settings.blockageDay) {
      return false
    }
    let now = moment()
    let monthEnd = moment(selectedDate, 'x').endOf('month').add(settings.blockageDay, 'day')
    return isManager(teamId) && (now.isBefore(monthEnd))
  }


  let groupe = groupesList.filter(g => g.id == selectedGroupe)

  return (
    <div>
      <div
        className={styles.topSticky}
        style={{ width: width + 100, backgroundColor: 'var(--bluegray-50)' }}>
        {
          <div
            className={
              styles.resourcesHeader + " text-center sideSticky"
            }
            style={{ backgroundColor: 'var(--bluegray-50)' }}
          >
            <label className={styles.tableHeaderLabel}>
              <Langs str="Collabs" /> ({uniqueCollabsIds.length})
            </label>
            {selectedGroupe !== 0 &&
              isAdmin(userRoleId) && (!groupe[0] || isManager(groupe[0].id_team, config.scopes.groups)) ? (
              <ImageButton
                onClick={() => handleAddUser()}
                image={addUser}
                imageFocus={addUserFocused}
                active={selectedGroupe && selectedGroupe !== 0}
                width={28}
              />
            ) : null}
          </div>
        }
        <div className={styles.datesGridLayout}>
          <GridLayout
            className={styles.datesHeader}
            layout={datesLayout}
            width={width}
            cols={datesLayout.length}
            rowHeight={height}
            preventCollision={true}
            isDroppable={false}
          >
            {datesLayout.map((item) => {
              return (
                <div key={item["i"]}>
                  <DateCell
                    key={`DateCell_${item["i"]}`}
                    data={item.data}
                    isDayOff={checkDayOff(item.data)}
                    amShifts={amShifts[item.x]}
                    pmShifts={pmShifts[item.x]}
                    nightShifts={nightShifts[item.x]}
                  />
                </div>
              )
            })}
          </GridLayout>
        </div>
      </div>

      {resourcesLayout}

      <ConfirmModal
        show={showConfirmAddShiftPopup}
        title={<Langs str='ADD_SHIFT' />}
        message={errorMessage}
        onConfirm={() => handleAddShift()}
        onHide={handleCancelAddShift}
      />
      <ConfirmModal
        show={showConfirmReplaceShiftPopup}
        title={<Langs str='REPLACE_SHIFT' />}
        message={errorMessage}
        onConfirm={() => handleUpdateShift()}
        onHide={handleCancelUpdateShift}
      />
    </div>
  )
}
